.whole {
  padding: 16px;
  > div {
    width: 100%;
  }
  .header {
    display: flex;
    align-items: center;
    .month {
      flex: 1;
      display: flex;
      flex-flow: column nowrap;
      .date {
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
    .control {
      display: grid;
      grid-template-columns: repeat(2, 64px);
      gap: 8px;
    }
  }
  .days {
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 8px;
    text-align: center;
  }
}
