.whole {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  .title {
    text-align: center;
    font-size: 3rem;
  }
  .toggle {
    font-weight: bolder;
    text-align: center;
    cursor: pointer;
  }
}
