.whole {
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  transition: var(--default-transition);
  user-select: none;
  cursor: pointer;
  &.checked {
    color: var(--primary);
  }
  .check {
    flex: none;
    padding: 4px 8px;
    padding-right: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    background-color: white;
    border-radius: 8px 0px 0px 8px;
  }
  .title {
    padding: 8px;
    padding-left: 4px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    background-color: white;
    border-radius: 0px 8px 8px 0px;
  }
}
