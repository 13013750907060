.whole {
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  .text {
    display: flex;
    > div:first-child {
      flex: 1;
      margin-right: 16px;
    }
    > div:last-child {
      flex: none;
      width: 40%;
    }
  }
  .options {
    display: flex;
    justify-content: space-between;
  }
  .tab {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
}
