.bill {
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  background-color: white;
  border-radius: 24px;
  &.canceled {
    background-color: #ffffffaa;
    .brief .title {
      text-decoration: line-through;
    }
  }
  .brief {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      flex-flow: column nowrap;
      .title {
        font-weight: bold;
      }
      .time {
        margin-top: 8px;
        color: grey;
        font-size: 0.8rem;
      }
    }
    .right {
      flex: none;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-end;
      .amount {
        font-size: 1.5rem;
        font-weight: bolder;
        color: var(--primary);
      }
      .prepaid {
        margin-top: 8px;
        font-size: 0.9rem;
      }
    }
  }
  .detail {
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    background-color: var(--primary);
    border-radius: 16px;
    box-shadow: 2px 2px 4px 0px transparentize($color: black, $amount: 0.9) inset;
    .tags {
      font-size: 0.9rem;
      letter-spacing: 0.1rem;
      line-height: 1.4rem;
    }
  }
}
