.whole {
  height: calc(100vh - 32px);
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  .header {
    flex: none;
    width: 100%;
    height: 48px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    user-select: none;
    .back {
      padding: 0px 16px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      background-color: white;
      border-radius: 24px;
      cursor: pointer;
      box-shadow: var(--default-shadow);
    }
    .actions {
      display: flex;
      flex-flow: row nowrap;
      .action {
        width: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
        background-color: white;
        border-radius: 50%;
        cursor: pointer;
        box-shadow: var(--default-shadow);
      }
    }
  }
  .content {
    flex: 1;
    margin: -6px;
    margin-top: 16px;
    padding: 6px;
    width: calc(100% + 12px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
