.whole {
  width: 100%;
  button {
    padding: 0;
    width: 100%;
    height: 64px;
    appearance: none;
    outline: none;
    border: none;
    background-color: white;
    border-radius: 8px;
    box-shadow: 2px 2px 4px 0px white;
    font-weight: bold;
    color: var(--primary);
    user-select: none;
    font-size: 1.5rem;
    &:active {
      box-shadow: 1px 1px 4px 0px white;
      transform: translate(1px, 1px);
    }
    &.small {
      height: 48px;
      font-size: 1.2rem;
    }
    &.number {
      font-size: 1.5rem;
    }
  }
}
