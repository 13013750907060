.whole {
  .date {
    width: 100%;
    margin: 0px;
    padding: 8px 8px;
    appearance: none;
    border: none;
    font-size: 1rem;
    color: var(--text);
    border-radius: 8px;
    box-shadow: 2px 2px 4px 0px white;
    &::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator {
      display: none;
      appearance: none;
    }
  }
}
