.whole {
  padding: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  font-family: 'Space Mono', monospace;
  .block {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    .time {
      font-size: 1rem;
    }
    .amount {
      font-size: 1.5rem;
    }
  }
}
