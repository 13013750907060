.whole {
  margin: -2px;
  display: flex;
  flex-flow: row wrap;
  .tag {
    margin: 2px;
    padding: 8px;
    position: relative;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    opacity: 0.5;
    user-select: none;
    cursor: pointer;
    transition: var(--default-transition);
    &.selected {
      opacity: 1;
      transform: translate(-1px, -1px);
      box-shadow: 2px 2px 4px 0px transparentize($color: black, $amount: 0.95);
    }
    &.editing {
      animation: tremble 0.2s ease-in-out infinite;
    }
    .delete {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-32%, -32%);
      color: red;
    }
  }
  @keyframes tremble {
    0%, 100% { transform: rotate(-2deg); }
    50% { transform: rotate(2deg); }
  }
  .control {
    span {
      font-size: 1em;
    }
  }
}
