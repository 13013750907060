@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@700&display=swap');

:root {
  font-size: 16px;
  --primary: #F9D568;
  --text: #333;
  --default-transition: 0.2s ease-in-out;
  --default-shadow: 2px 2px 4px 0px #00000020;
  --default-drop-shadow: drop-shadow(2px 2px 4px #00000020);
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary);
  color: var(--text)
}

hr {
  width: 100%;
  border-top: 1px solid white;
  border-bottom: 1px dashed white;
}

.rotating {
  transform-origin: 50% 50%;
  animation: rotating 1s cubic-bezier(0.34, 1.56, 0.64, 1) 0s infinite;
}

@keyframes rotating {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.border {
  border-radius: 8px;
  border: 2px solid white;
  box-shadow: 1px 1px 1px 1px transparentize($color: black, $amount: 0.95);
}

.number {
  font-family: 'Space Mono', monospace;
}
