.whole {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  box-shadow: 2px 2px 4px 0px white;
  &.small {
    height: 48px;
    border-radius: 6px;
    input {
      font-size: 1.5rem;
    }
  }
  input {
    padding: 0px 16px;
    width: 100%;
    appearance: none;
    outline: none;
    border: none;
    background: transparent;
    font-size: 2rem;
    &, &::placeholder {
      color: var(--primary);
    }
    &.textNumber {
      text-align: right;
      font-size: 2.2rem;
    }
  }
}
