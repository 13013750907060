.whole {
  position: fixed;
  width: 100%;
  bottom: 64px;
  left: 0;
  z-index: 100;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  pointer-events: none;
  .message {
    margin-top: 12px;
    padding: 16px 32px;
    background-color: transparentize($color: white, $amount: 0.2);
    backdrop-filter: blur(8px);
    border-radius: 8px;
    box-shadow: 4px 4px 4px 0px transparentize($color: #000000, $amount: 0.95);
    pointer-events: all;
    animation: scalein 0.5s cubic-bezier(0.34, 1.56, 0.64, 1), scaleout 0.2s ease-in-out var(--delay);
  }
}

@keyframes scalein {
  0% { opacity: 0; transform: scale(0); }
  100% { opacity: 1; transform: scale(1); }
}

@keyframes scaleout {
  0% { transform: scaleY(1); }
  100% { transform: scaleY(0); }
}
