.whole {
  display: grid;
  grid-template-columns: 1fr;
  align-content: start;
  gap: 16px;
  .filter {
    padding: 16px;
    display: grid;
    gap: 8px;
    .date {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      gap: 8px;
      align-items: center;
    }
    .statistics {
      display: flex;
      flex-flow: row-reverse wrap;
    }
  }
  .bills {
    display: grid;
    grid-template-columns: 1fr;
    align-content: start;
    gap: 8px;
    filter: var(--default-drop-shadow);
    .more {
      text-align: center;
      font-weight: bold;
    }
  }
}
