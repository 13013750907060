.whole {
  text-align: center;
  &.today {
    position: relative;
    &::before {
      content: '';
      z-index: -1;
      position: absolute;
      left: 50%;
      top: 50%;
      height: 3rem;
      width: 3rem;
      transform: translate(-50%, -50%);
      border-radius: 0.5rem;
      background-color: transparentize($color: white, $amount: 0.1);
    }
  }
  &.week {
    padding: 8px;
    grid-column: 1 / 8;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-radius: 8px;
    background-color: transparentize($color: white, $amount: 0.7);
    &.today {
      background-color: transparentize($color: white, $amount: 0.1);
      &::before {
        content: none;
      }
    }
    .day {
      font-size: 1rem;
    }
  }
  .day {
    font-size: 1.3rem;
  }
}