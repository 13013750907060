.whole {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: blurin 0.5s ease-in-out forwards;
  @keyframes blurin {
    0% { backdrop-filter: blur(0px) brightness(100%); }
    100% { backdrop-filter: blur(32px) brightness(90%); }
  }
  .content {
    padding: 32px;
    display: flex;
    flex-flow: column nowrap;
    background-color: var(--primary);
    border-radius: 32px;
    box-shadow: 0px 0px 32px 0px transparentize($color: #000000, $amount: 0.9);
    animation: slidein 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
    @keyframes slidein {
      0% { opacity: 0; transform: translateY(-100%) scale(0); }
      100% { opacity: 1; transform: translateY(0%) scale(1); }
    }
    .wrapper {
      min-height: 64px;
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;
    }
    .control {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
      > div {
        width: 120px;
        margin-left: 16px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
