@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css2?family=Space+Mono:wght@700&display=swap);
:root{font-size:16px;--primary: #F9D568;--text: #333;--default-transition: 0.2s ease-in-out;--default-shadow: 2px 2px 4px 0px #00000020;--default-drop-shadow: drop-shadow(2px 2px 4px #00000020)}*{box-sizing:border-box}body{margin:0;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:var(--primary);color:var(--text)}hr{width:100%;border-top:1px solid #fff;border-bottom:1px dashed #fff}.rotating{transform-origin:50% 50%;animation:rotating 1s cubic-bezier(0.34, 1.56, 0.64, 1) 0s infinite}@keyframes rotating{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}.border{border-radius:8px;border:2px solid #fff;box-shadow:1px 1px 1px 1px rgba(0,0,0,.05)}.number{font-family:"Space Mono",monospace}
.App_whole__3sei0{margin:0 auto;padding:16px;width:100%;max-width:600px}
.MyButton_whole__2TmY1{width:100%}.MyButton_whole__2TmY1 button{padding:0;width:100%;height:64px;-webkit-appearance:none;appearance:none;outline:none;border:none;background-color:#fff;border-radius:8px;box-shadow:2px 2px 4px 0px #fff;font-weight:bold;color:var(--primary);-webkit-user-select:none;user-select:none;font-size:1.5rem}.MyButton_whole__2TmY1 button:active{box-shadow:1px 1px 4px 0px #fff;transform:translate(1px, 1px)}.MyButton_whole__2TmY1 button.MyButton_small__3Sew8{height:48px;font-size:1.2rem}.MyButton_whole__2TmY1 button.MyButton_number__3Zrch{font-size:1.5rem}
.MyInput_whole__2RFJP{width:100%;height:64px;display:flex;align-items:center;background-color:#fff;border-radius:8px;box-shadow:2px 2px 4px 0px #fff}.MyInput_whole__2RFJP.MyInput_small__10cvJ{height:48px;border-radius:6px}.MyInput_whole__2RFJP.MyInput_small__10cvJ input{font-size:1.5rem}.MyInput_whole__2RFJP input{padding:0px 16px;width:100%;-webkit-appearance:none;appearance:none;outline:none;border:none;background:rgba(0,0,0,0);font-size:2rem}.MyInput_whole__2RFJP input,.MyInput_whole__2RFJP input::placeholder{color:var(--primary)}.MyInput_whole__2RFJP input.MyInput_textNumber__-NO-d{text-align:right;font-size:2.2rem}
.AmountInput_whole__26ADQ{display:grid;grid-template-columns:repeat(4, 1fr);grid-gap:12px;gap:12px}.AmountInput_whole__26ADQ>div:first-child{grid-column-start:1;grid-column-end:3}
.MyCheckbox_whole__3uYgE{width:auto;display:flex;flex-flow:row nowrap;transition:var(--default-transition);-webkit-user-select:none;user-select:none;cursor:pointer}.MyCheckbox_whole__3uYgE.MyCheckbox_checked__KJLLB{color:var(--primary)}.MyCheckbox_whole__3uYgE .MyCheckbox_check__1mQt8{flex:none;padding:4px 8px;padding-right:0;display:flex;flex-flow:row nowrap;align-items:center;background-color:#fff;border-radius:8px 0px 0px 8px}.MyCheckbox_whole__3uYgE .MyCheckbox_title__UIOwH{padding:8px;padding-left:4px;display:flex;flex-flow:row nowrap;align-items:center;background-color:#fff;border-radius:0px 8px 8px 0px}
.MyMessage_whole__3PpH8{position:fixed;width:100%;bottom:64px;left:0;z-index:100;display:flex;flex-flow:column nowrap;justify-content:flex-start;align-items:center;pointer-events:none}.MyMessage_whole__3PpH8 .MyMessage_message__2Yabr{margin-top:12px;padding:16px 32px;background-color:rgba(255,255,255,.8);-webkit-backdrop-filter:blur(8px);backdrop-filter:blur(8px);border-radius:8px;box-shadow:4px 4px 4px 0px rgba(0,0,0,.05);pointer-events:all;animation:MyMessage_scalein__33JCc .5s cubic-bezier(0.34, 1.56, 0.64, 1),MyMessage_scaleout__2l9Lw .2s ease-in-out var(--delay)}@keyframes MyMessage_scalein__33JCc{0%{opacity:0;transform:scale(0)}100%{opacity:1;transform:scale(1)}}@keyframes MyMessage_scaleout__2l9Lw{0%{transform:scaleY(1)}100%{transform:scaleY(0)}}
.Summary_whole__TAO7l{padding:8px;display:grid;grid-template-columns:1fr 1fr;grid-gap:8px;gap:8px;font-family:"Space Mono",monospace}.Summary_whole__TAO7l .Summary_block__16m7c{display:flex;flex-flow:column nowrap;justify-content:center;align-items:center}.Summary_whole__TAO7l .Summary_block__16m7c .Summary_time__3GBLi{font-size:1rem}.Summary_whole__TAO7l .Summary_block__16m7c .Summary_amount__1ZMp8{font-size:1.5rem}
.MyModal_whole__2lkpE{position:fixed;width:100vw;height:100vh;left:0;top:0;display:flex;justify-content:center;align-items:center;animation:MyModal_blurin__2Yg0f .5s ease-in-out forwards}@keyframes MyModal_blurin__2Yg0f{0%{-webkit-backdrop-filter:blur(0px) brightness(100%);backdrop-filter:blur(0px) brightness(100%)}100%{-webkit-backdrop-filter:blur(32px) brightness(90%);backdrop-filter:blur(32px) brightness(90%)}}.MyModal_whole__2lkpE .MyModal_content__3deEz{padding:32px;display:flex;flex-flow:column nowrap;background-color:var(--primary);border-radius:32px;box-shadow:0px 0px 32px 0px rgba(0,0,0,.1);animation:MyModal_slidein__3E59g .5s cubic-bezier(0.34, 1.56, 0.64, 1) forwards}@keyframes MyModal_slidein__3E59g{0%{opacity:0;transform:translateY(-100%) scale(0)}100%{opacity:1;transform:translateY(0%) scale(1)}}.MyModal_whole__2lkpE .MyModal_content__3deEz .MyModal_wrapper__1iTTJ{min-height:64px;display:grid;grid-template-columns:1fr;grid-gap:16px;gap:16px}.MyModal_whole__2lkpE .MyModal_content__3deEz .MyModal_control__3RkDR{margin-top:16px;display:flex;justify-content:flex-end}.MyModal_whole__2lkpE .MyModal_content__3deEz .MyModal_control__3RkDR>div{width:120px;margin-left:16px}.MyModal_whole__2lkpE .MyModal_content__3deEz .MyModal_control__3RkDR>div:first-child{margin-left:0}
.TagInput_whole__sg09y{margin:-2px;display:flex;flex-flow:row wrap}.TagInput_whole__sg09y .TagInput_tag__L7QSK{margin:2px;padding:8px;position:relative;display:flex;align-items:center;background-color:#fff;border-radius:8px;opacity:.5;-webkit-user-select:none;user-select:none;cursor:pointer;transition:var(--default-transition)}.TagInput_whole__sg09y .TagInput_tag__L7QSK.TagInput_selected__ukwdt{opacity:1;transform:translate(-1px, -1px);box-shadow:2px 2px 4px 0px rgba(0,0,0,.05)}.TagInput_whole__sg09y .TagInput_tag__L7QSK.TagInput_editing__2bpQH{animation:TagInput_tremble__35MRQ .2s ease-in-out infinite}.TagInput_whole__sg09y .TagInput_tag__L7QSK .TagInput_delete__1d7zx{position:absolute;top:0;left:0;transform:translate(-32%, -32%);color:red}@keyframes TagInput_tremble__35MRQ{0%,100%{transform:rotate(-2deg)}50%{transform:rotate(2deg)}}.TagInput_whole__sg09y .TagInput_control__7oNDc span{font-size:1em}
.Home_whole__roiBn{display:grid;grid-template-columns:1fr;grid-gap:12px;gap:12px}.Home_whole__roiBn .Home_text__H81xa{display:flex}.Home_whole__roiBn .Home_text__H81xa>div:first-child{flex:1 1;margin-right:16px}.Home_whole__roiBn .Home_text__H81xa>div:last-child{flex:none;width:40%}.Home_whole__roiBn .Home_options__IVodL{display:flex;justify-content:space-between}.Home_whole__roiBn .Home_tab__hBre-{display:grid;grid-template-columns:repeat(3, 1fr);grid-gap:16px;gap:16px}
.Frame_whole__37jRk{height:calc(100vh - 32px);display:flex;flex-flow:column nowrap;align-items:flex-start}.Frame_whole__37jRk .Frame_header__121yV{flex:none;width:100%;height:48px;display:flex;flex-flow:row nowrap;justify-content:space-between;-webkit-user-select:none;user-select:none}.Frame_whole__37jRk .Frame_header__121yV .Frame_back__2rsPe{padding:0px 16px;display:flex;flex-flow:row nowrap;align-items:center;background-color:#fff;border-radius:24px;cursor:pointer;box-shadow:var(--default-shadow)}.Frame_whole__37jRk .Frame_header__121yV .Frame_actions__33nxW{display:flex;flex-flow:row nowrap}.Frame_whole__37jRk .Frame_header__121yV .Frame_actions__33nxW .Frame_action__10dlo{width:48px;display:flex;justify-content:center;align-items:center;margin-left:8px;background-color:#fff;border-radius:50%;cursor:pointer;box-shadow:var(--default-shadow)}.Frame_whole__37jRk .Frame_content__Maj8m{flex:1 1;margin:-6px;margin-top:16px;padding:6px;width:calc(100% + 12px);overflow-y:auto}.Frame_whole__37jRk .Frame_content__Maj8m::-webkit-scrollbar{display:none}
.MyDateInput_whole__mvdcW .MyDateInput_date__16rwo{width:100%;margin:0px;padding:8px 8px;-webkit-appearance:none;appearance:none;border:none;font-size:1rem;color:var(--text);border-radius:8px;box-shadow:2px 2px 4px 0px #fff}.MyDateInput_whole__mvdcW .MyDateInput_date__16rwo::-webkit-inner-spin-button,.MyDateInput_whole__mvdcW .MyDateInput_date__16rwo::-webkit-calendar-picker-indicator{display:none;-webkit-appearance:none;appearance:none}
.BillCard_bill__2MN__{padding:16px;display:grid;grid-template-columns:1fr;grid-gap:16px;gap:16px;background-color:#fff;border-radius:24px}.BillCard_bill__2MN__.BillCard_canceled__3xGNz{background-color:rgba(255,255,255,.6666666667)}.BillCard_bill__2MN__.BillCard_canceled__3xGNz .BillCard_brief__3SgRU .BillCard_title__3jEO8{text-decoration:line-through}.BillCard_bill__2MN__ .BillCard_brief__3SgRU{display:flex;flex-flow:row nowrap;align-items:center}.BillCard_bill__2MN__ .BillCard_brief__3SgRU .BillCard_left__2VtRP{flex:1 1;display:flex;flex-flow:column nowrap}.BillCard_bill__2MN__ .BillCard_brief__3SgRU .BillCard_left__2VtRP .BillCard_title__3jEO8{font-weight:bold}.BillCard_bill__2MN__ .BillCard_brief__3SgRU .BillCard_left__2VtRP .BillCard_time__1_jdN{margin-top:8px;color:gray;font-size:.8rem}.BillCard_bill__2MN__ .BillCard_brief__3SgRU .BillCard_right__2zGGS{flex:none;display:flex;flex-flow:column nowrap;justify-content:center;align-items:flex-end}.BillCard_bill__2MN__ .BillCard_brief__3SgRU .BillCard_right__2zGGS .BillCard_amount__2amN5{font-size:1.5rem;font-weight:bolder;color:var(--primary)}.BillCard_bill__2MN__ .BillCard_brief__3SgRU .BillCard_right__2zGGS .BillCard_prepaid__Oh8iZ{margin-top:8px;font-size:.9rem}.BillCard_bill__2MN__ .BillCard_detail__2AAa6{padding:16px;display:grid;grid-template-columns:1fr;grid-gap:16px;gap:16px;background-color:var(--primary);border-radius:16px;box-shadow:2px 2px 4px 0px rgba(0,0,0,.1) inset}.BillCard_bill__2MN__ .BillCard_detail__2AAa6 .BillCard_tags__2c5rx{font-size:.9rem;letter-spacing:.1rem;line-height:1.4rem}
.Bill_whole__2lWK_{display:grid;grid-template-columns:1fr;align-content:start;grid-gap:16px;gap:16px}.Bill_whole__2lWK_ .Bill_filter__20KNJ{padding:16px;display:grid;grid-gap:8px;gap:8px}.Bill_whole__2lWK_ .Bill_filter__20KNJ .Bill_date__16G3J{display:grid;grid-template-columns:1fr auto 1fr;grid-gap:8px;gap:8px;align-items:center}.Bill_whole__2lWK_ .Bill_filter__20KNJ .Bill_statistics__3L93H{display:flex;flex-flow:row-reverse wrap}.Bill_whole__2lWK_ .Bill_bills__S2SM_{display:grid;grid-template-columns:1fr;align-content:start;grid-gap:8px;gap:8px;filter:var(--default-drop-shadow)}.Bill_whole__2lWK_ .Bill_bills__S2SM_ .Bill_more__1DW_Z{text-align:center;font-weight:bold}
.Login_whole__3KNod{display:grid;grid-template-columns:1fr;grid-gap:16px;gap:16px}.Login_whole__3KNod .Login_title__IiLSf{text-align:center;font-size:3rem}.Login_whole__3KNod .Login_toggle__1QVTz{font-weight:bolder;text-align:center;cursor:pointer}
.Statistic_whole__1BfhZ{display:grid;grid-template-columns:1fr;grid-gap:16px;gap:16px;align-content:start}
.Calendar_whole__2VdYv{padding:16px}.Calendar_whole__2VdYv>div{width:100%}.Calendar_whole__2VdYv .Calendar_header__3pvL2{display:flex;align-items:center}.Calendar_whole__2VdYv .Calendar_header__3pvL2 .Calendar_month__1TtLy{flex:1 1;display:flex;flex-flow:column nowrap}.Calendar_whole__2VdYv .Calendar_header__3pvL2 .Calendar_month__1TtLy .Calendar_date__1juaU{font-size:1.5rem;font-weight:bold}.Calendar_whole__2VdYv .Calendar_header__3pvL2 .Calendar_control__AEU97{display:grid;grid-template-columns:repeat(2, 64px);grid-gap:8px;gap:8px}.Calendar_whole__2VdYv .Calendar_days__3YjAN{margin-top:16px;display:grid;grid-template-columns:repeat(7, 1fr);grid-gap:8px;gap:8px;text-align:center}
.CalendarAmount_whole__2my-y{height:1rem}.CalendarAmount_whole__2my-y *{font-size:1rem}
.CalendarEntry_whole__14jrh{text-align:center}.CalendarEntry_whole__14jrh.CalendarEntry_today__3YDvm{position:relative}.CalendarEntry_whole__14jrh.CalendarEntry_today__3YDvm::before{content:"";z-index:-1;position:absolute;left:50%;top:50%;height:3rem;width:3rem;transform:translate(-50%, -50%);border-radius:.5rem;background-color:rgba(255,255,255,.9)}.CalendarEntry_whole__14jrh.CalendarEntry_week__2pgel{padding:8px;grid-column:1/8;display:flex;justify-content:space-between;align-items:baseline;border-radius:8px;background-color:rgba(255,255,255,.3)}.CalendarEntry_whole__14jrh.CalendarEntry_week__2pgel.CalendarEntry_today__3YDvm{background-color:rgba(255,255,255,.9)}.CalendarEntry_whole__14jrh.CalendarEntry_week__2pgel.CalendarEntry_today__3YDvm::before{content:none}.CalendarEntry_whole__14jrh.CalendarEntry_week__2pgel .CalendarEntry_day__3PXgk{font-size:1rem}.CalendarEntry_whole__14jrh .CalendarEntry_day__3PXgk{font-size:1.3rem}
